import { Link } from "@remix-run/react";
import { LinkTo, LinkToSmall } from "~/assets/icons/LinkTo";

export const LinkWithIcon = ({
  to,
  title,
  textColor,
  size = "large",
}: {
  to: string;
  title: string;
  textColor: "white" | "black";
  size?: "large" | "small";
}) => {
  return (
    <Link
      prefetch="viewport"
      style={{ color: textColor }}
      to={to}
      className={`font-medium ${
        size === "large"
          ? "animating-link-large text-xl"
          : "animating-link-small text-sm"
      } animating-link flex flex-row items-center gap-2 border-b-1 border-${textColor}`}
    >
      <span className="inline-block">
        {title}{" "}
        <div className="inline-block">
          {size === "large" ? (
            <LinkTo fill={textColor} />
          ) : (
            <LinkToSmall fill={textColor} />
          )}
        </div>
      </span>
      <div className="flex flex-row text-transparent">
        {title}

        <div className="mx-1 inline-block">
          {size === "large" ? (
            <LinkTo fill={"transparent"} />
          ) : (
            <LinkToSmall fill={"transparent"} />
          )}
        </div>
      </div>
      <span className="inline-block">
        {title}{" "}
        <div className="inline-block">
          {size === "large" ? (
            <LinkTo fill={textColor} />
          ) : (
            <LinkToSmall fill={textColor} />
          )}
        </div>
      </span>
    </Link>
  );
};
